<template>
    <div class="user-balance">
        <el-card class=" " :body-style="{height: '130px'}"  shadow="never">
            <div slot="header" class="clearfix">
                <span>我的余额</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-row class="content">
                <el-col :span="5">
                    <p>
                        <span>余额：{{user.money || 0.00}}</span>
                        <el-button @click="goRecharge" size="mini" type="danger">充值</el-button>
                    </p>
                </el-col>
                <el-col :span="1">

                </el-col>
                <el-col :span="16">
                   <p>温馨提示:</p>
                   <p>1、余额不会过期，暂不支持提现，不可转赠他人。</p>
                   <p>2、充值后的余额可直接在付费内容消费。</p>
                   <p>3、如需开消费发票请进入发票管理,开具增值税普通电子发票。</p>

                </el-col>
            </el-row>
        </el-card>
        <el-card class="box-card list-card"  shadow="never">
            <div slot="header" class="clearfix">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="全部记录" name="all">

                    </el-tab-pane>
                    <el-tab-pane label="充值记录" name="income">

                    </el-tab-pane>
                    <el-tab-pane label="支出记录" name="expend">

                    </el-tab-pane>

                </el-tabs>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-table
                    :data="listData.data"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="memo"
                        label="标题"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="money"
                        label="变更金额"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="after"
                        label="余额">
                </el-table-column>
                <el-table-column
                        prop="createtime"
                        label="日期">
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                style="text-align: center;margin-top: 10px"
                background
                layout="prev, pager, next"
                :current-page="listData.current_page"
                :page-size="Number(listData.per_page)"
                :page-count="listData.last_page"
                @current-change="currentChange"
                :total="listData.total">
        </el-pagination>
    </div>
</template>

<script>
    import {UserService} from "../../../api/user";
    import { mapGetters } from 'vuex'
    export default {
        name: "user-order-index",
        data() {
            return {
                activeName: 'all',
                page: 1,
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    data: [],
                    per_page: 1,
                    total: 0
                },
            }
        },
        computed:{
            ...mapGetters(['user']),
        },
        methods: {
            handleClick(tab, event) {
                this.page = 1
                this.getList()
            },
            goRecharge() {
                this.$pay.show({
                    type: 'recharge'
                })
            },
            goRead(item) {
                this.$router.push({
                    name: 'detailRead',
                    query: {
                        id: item.aid
                    }
                })
            },
            goDetail(item) {
                // // console.log('item', item)
                this.$router.push({
                    query: {
                        id: item.aid
                    },
                    name: 'detail'
                })
            },
            currentChange(page) {
                this.page = page
                this.getList()
            },
            down(item) {
                this.$pay.show({
                    id: item.aid,
                    type: 'down'
                })
            },
            getList() {
                UserService.moneylog({ type: this.activeName, page: this.page }).then(res => {
                    this.listData = res.data
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped lang="scss">
    .user-balance{
        .content{
            /*height: 200px;*/
            height: 100%;
            .left{
                span{
                    color: $font_1;
                    font-size: 18px;
                }
            }
            .el-col:nth-child(1) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                span{
                    font-size: 18px;
                }
                .el-button{
                    margin-left: 20px;
                    float: right;
                }
            }
            .el-col:nth-child(2) {
                width: 1px;
                height: 100%;
                background-color: $border_1;
                margin: 0 20px;

            }
            .el-col:nth-child(3) {
                padding: 10px 0;
                p{
                    font-size: 13px;
                    color: $font_2;
                    line-height: 1.6;
                }
            }
        }
        .list-card{
            height: 470px;
            margin-top: 13px;
        }
    }

</style>